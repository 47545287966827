var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal__container modal__container--overlay",
      class: { "h-justify-start@phone": _vm.licenseSelectionStep === 1 },
    },
    [
      _vm.licenseSelectionStep === 1
        ? _c("icon", {
            staticClass: "modalcloseButton",
            attrs: { name: "close" },
            nativeOn: {
              click: function ($event) {
                return _vm.exitLicenseOptionsSelectionModal.apply(
                  null,
                  arguments
                )
              },
            },
          })
        : _vm._e(),
      _vm.licenseSelectionStep === 1
        ? _c("div", { staticClass: "container h-padding-80" }, [
            _vm.noDeals
              ? _c("div", { staticClass: "modal__heading" }, [
                  _c("h2", { staticClass: "modal__title" }, [
                    _vm._v("License Options"),
                  ]),
                  _c("p", { staticClass: "modal__subtitle" }, [
                    _vm._v("Please select a license option to proceed."),
                  ]),
                ])
              : !_vm.sameDealForAllLicenses
              ? _c("div", { staticClass: "modal__heading" }, [
                  _c("h2", { staticClass: "modal__title" }, [
                    _vm._v("License Options"),
                  ]),
                  _c("p", { staticClass: "modal__subtitle" }, [
                    _vm._v(
                      "Please add beats of the same license to the cart prior to checking out, to activate the deals."
                    ),
                  ]),
                ])
              : _c("div", { staticClass: "modal__heading" }, [
                  _c(
                    "div",
                    { staticClass: "modal__title" },
                    [
                      _vm._v("Deals:"),
                      _c("icon", {
                        staticStyle: { margin: "0 8px" },
                        attrs: { name: "deal" },
                      }),
                      _vm._v(
                        "Buy " +
                          _vm._s(_vm.sameDealForAllLicenses.dealBuy) +
                          " Get " +
                          _vm._s(_vm.sameDealForAllLicenses.dealGet) +
                          " Free"
                      ),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "modal__subtitle" }, [
                    _vm._v(
                      "Please add " +
                        _vm._s(
                          _vm.sameDealForAllLicenses.dealBuy +
                            _vm.sameDealForAllLicenses.dealGet
                        ) +
                        " " +
                        _vm._s(_vm.beat.producer.display_name) +
                        " beats of the same license to the cart prior to checking out, to activate the buy " +
                        _vm._s(_vm.sameDealForAllLicenses.dealBuy) +
                        " get " +
                        _vm._s(_vm.sameDealForAllLicenses.dealGet) +
                        " free deal."
                    ),
                  ]),
                ]),
            _c(
              "div",
              { staticClass: "flexbox flexbox--center flexbox--column@phone" },
              _vm._l(_vm.licenseOptions, function (license) {
                return _c("license-option-box-detailed", {
                  key: license.id,
                  class: {
                    isDisabled:
                      _vm.boughtLicense &&
                      license.value <= _vm.boughtLicense.value,
                  },
                  attrs: {
                    license: license,
                    "exclusive-license-price": _vm.beat.price_for_exclusive,
                    "bought-license-price": _vm.boughtLicense
                      ? _vm.boughtLicense.price
                      : 0,
                    "producer-name": _vm.beat.producer.display_name,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.selectLicense(license)
                    },
                  },
                })
              }),
              1
            ),
            _c(
              "div",
              {
                staticClass: "cartNavigation h-justifyCenter h-textCenter",
                staticStyle: { "margin-top": "5rem" },
              },
              [
                _c("icon", {
                  staticStyle: { "margin-right": "5px" },
                  attrs: { name: "info" },
                }),
                _vm._v("Check out the "),
                _c(
                  "a",
                  {
                    staticClass: "h-textUnderline",
                    on: {
                      click: function ($event) {
                        return _vm.openPdfLicenseSummary(_vm.beat.producer.slug)
                      },
                    },
                  },
                  [_vm._v("Licensing Information")]
                ),
                _vm._v(" in details."),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.licenseSelectionStep === 2
        ? _c(
            "div",
            { staticClass: "container container--xs" },
            [
              _c("h3", { staticClass: "modal__title h-textCenter" }, [
                _vm._v("You added this item to your cart:"),
              ]),
              _c("cart-item-beat", {
                attrs: {
                  "cart-item": _vm.selectedItem,
                  "bought-license-price": _vm.boughtLicense
                    ? _vm.boughtLicense.price
                    : 0,
                },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "cartNavigation cartNavigation--btnFullWidthWrapper",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--lg btn--grey",
                      on: { click: _vm.exitLicenseOptionsSelectionModal },
                    },
                    [_vm._v("CONTINUE SHOPPING")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--lg btn--secondary",
                      on: {
                        click: function ($event) {
                          return _vm.exitLicenseOptionsSelectionModal({
                            goToCart: true,
                          })
                        },
                      },
                    },
                    [_vm._v("CHECKOUT AND DOWNLOAD")]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }