import { render, staticRenderFns } from "./LicenseOptionBoxSimple.vue?vue&type=template&id=43d83b53&scoped=true&lang=pug&"
import script from "./LicenseOptionBoxSimple.vue?vue&type=script&lang=ts&"
export * from "./LicenseOptionBoxSimple.vue?vue&type=script&lang=ts&"
import style0 from "./LicenseOptionBoxSimple.vue?vue&type=style&index=0&id=43d83b53&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43d83b53",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/clickandrap_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('43d83b53')) {
      api.createRecord('43d83b53', component.options)
    } else {
      api.reload('43d83b53', component.options)
    }
    module.hot.accept("./LicenseOptionBoxSimple.vue?vue&type=template&id=43d83b53&scoped=true&lang=pug&", function () {
      api.rerender('43d83b53', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/marketplace/components/LicenseOptionBoxSimple.vue"
export default component.exports