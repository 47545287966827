var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "license-box" }, [
    _vm.licenseIsAlreadyInCart
      ? _c(
          "div",
          { staticClass: "license-box__checkbox" },
          [_c("icon", { attrs: { name: "check" } })],
          1
        )
      : _vm._e(),
    _c("h3", { staticClass: "license-box__title" }, [
      _vm._v(_vm._s(_vm.license.name)),
    ]),
    _c("p", { staticClass: "license-box__price" }, [
      _c("span", { staticClass: "license-box__currency" }, [_vm._v("$")]),
      _vm._v(_vm._s(_vm.calculatedPrice)),
    ]),
    _c(
      "p",
      { staticClass: "license-box__types" },
      [
        _vm.license.mp3 ? [_vm._v("MP3")] : _vm._e(),
        _vm.license.wav ? [_vm._v(", WAV")] : _vm._e(),
        _vm.license.trackout ? [_vm._v(", Trackout")] : _vm._e(),
      ],
      2
    ),
    _c(
      "p",
      { staticClass: "license-box__deal" },
      [
        _c("icon", {
          staticClass: "license-box__dealIcon",
          attrs: { name: "deal" },
        }),
        _vm.license.deal_get == 0
          ? _c("span", [_vm._v("No Deals")])
          : _c("span", [
              _vm._v(
                "Buy " +
                  _vm._s(_vm.license.deal_buy) +
                  " Get " +
                  _vm._s(_vm.license.deal_get) +
                  " Free"
              ),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }